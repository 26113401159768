<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>个人联系方式</v-toolbar-title>
          </v-toolbar>

          <v-list two-line>

            <v-list-item active-class="pink--text">
              <v-list-item-icon>
                <v-icon color="indigo" @click="$router.push('/')">fa-user</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>https://evgo2017.com</v-list-item-title>
                <v-list-item-subtitle>个人网站</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo">fa-github</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>https://github.com/evgo2017</v-list-item-title>
                <v-list-item-subtitle>GitHub</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo" href="mailto:evgo2017@qq.com">fa-envelope</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>evgo2017@qq.com</v-list-item-title>
                <v-list-item-subtitle>邮箱</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo">fa-weixin</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>随风前行</v-list-item-title>
                <v-list-item-subtitle>公众号 evgo2017</v-list-item-subtitle>
                <v-img contain height="170" src="/imgs/link/public-account.png"></v-img>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>友链</v-toolbar-title>
          </v-toolbar>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo">fa-user</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>待补充</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Link',
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
